import React from 'react'
import NavMenu from '../components/NavMenu'
import "./Home.css"
import Hero from '../components/Hero/Hero'
import AlternateChannels from '../components/AlternateChannels/AlternateChannels'
import Finedge from '../components/FinedgeCore/Finedge'
import ProductCoverage from '../components/ProductCoverage/ProductCoverage'
import FinedgeBanking from '../components/FinedgeOnlineBanking/FinedgeBanking'
import MobileBanking from '../components/MobileBanking/MobileBanking'
import InternetBanking from "../components/InternetBanking/InternetBanking"
import Promises from '../components/Promises/Promises'
import BankingPackage from '../components/BankingPackage/BankingPackage'
import Footer from '../components/Footer/Footer'
import RequestModal from "../components/RequestDemo/Demo"
import {AiOutlineClose} from "react-icons/ai"
import bannerImage from "../images/imageBanner.png";

function Home() {
  const off = () =>{
    document.getElementById("overlay").style.display = "none";
    document.getElementById("exampleModal").style.display = "none"
  }
  return (
    <div>
    <div className="container">
      <div id="overlay" onClick={off}></div>
      <NavMenu /> 
        <Hero />
      </div>
        <img className="imageBanner" width={"100%"} src={bannerImage} alt="" />
      

               

<div className="container">
{/* <!-- Modal --> */}
<div className="modalContent hidden fade fixed z-20  h-full outline-none overflow-y-auto"
  id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog relative w-auto pointer-events-none">
    <div
      className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
      <div
        className="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
        <h5 className="text-xl font-medium leading-normal text-gray-800" id="exampleModalLabel">Schedule Demo</h5>
        <button type="button"
          className="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
          onClick={()=>off()}><AiOutlineClose/></button>
      </div>
      <div className="modal-body z-20 relative p-4">
        <RequestModal />
      </div>

    </div>
  </div>
</div>


        <AlternateChannels />
        <Finedge />
        <ProductCoverage />
        <FinedgeBanking />
        <MobileBanking />
        <InternetBanking />
        <Promises />
        <BankingPackage />
        <Footer />
        </div>
    </div>
  )
}

export default Home
import React from 'react'
import globe from "../../images/globe.svg"
import "./FinedgeBanking.css"


function FinedgeBanking() {
  return (
    <div id="online-banking">
        <img className="mx-auto" src={globe} alt="" />

        <div className="text-center text-black dark:text-white">
        <h1 className="finedgeTitle">FinEdge <br/>Online Banking</h1>
        <p className="finedgeDescription">FinEdge online banking is an advanced cloud-native internet banking and mobile banking solution that helps banks onboard service and engage more efficiently.</p>
        </div>
    </div>
  )
}

export default FinedgeBanking
import React from 'react'
import { FiCheck } from 'react-icons/fi'
import radio from "../../images/radio.svg"


function ProductGrid(props) {
  return (
    <div className="bg-white dark:bg-black text-black dark:text-white gridContainer text-start">
        <img src={localStorage.getItem("color-theme")==="dark"? radio : radio} alt="featureIcon"/>
        <h2 className="productGridTitle">{props.feature.title}</h2>

        <ul>
            {props.feature.features.map((feature,i)=>(
            <li className="flex text-black dark:text-white " key={i}> <FiCheck className="my-1 mr-2"/>{feature}</li>
            ))
            }
        </ul>
    </div>
  )
}

export default ProductGrid
import React from 'react'
import "./Footer.css"
import arrowWhite from "../../images/arrowWhite.svg"
import arrowBlack from "../../images/blackArrow.svg"
import horizontalArrow from "../../images/horizontalArrow.svg"


function Footer() {
  const sendEmail = () =>{
    window.open("mailto:info@thefifthlab.com","_blank")
  }

  

  const showModal = () => {
      document.getElementById("overlay").style.display = "block";
      document.getElementById("exampleModal").style.display = "block"
  }



  return (
    <div className="footerContainer text-black dark:text-white">
    <div className="footerFlex">
        <div>
            <h1 className="footerHeader">FinEdge</h1>
            <p className="py-4 footerDescription text-black dark:text-gray-400">Advanced cloud-native internet banking and mobile banking solution that helps banks onboard, service and engage more efficiently</p>
        </div>

        <div>
            <hr className="shortLine bg-black dark:bg-white"/>
            <h2 className="my-5 reachOut">Reach out to our FinEdge team</h2>
            <div className="footerButtons">
            <button onClick={()=>showModal()} className="primaryButton">Schedule Demo <img src={arrowWhite} className="mt-2" alt=""/></button>
            <button className="secondaryButton" onClick={()=>sendEmail()}>Send A Mail<img src={localStorage.getItem('color-theme')==='dark'?horizontalArrow:arrowBlack} alt=""/></button>
            </div>
        </div>
    </div>
    </div>
  )
}

export default Footer
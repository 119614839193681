import React from 'react'
import { BsArrowDown } from 'react-icons/bs'
import "./MobileBanking.css"
import mobileBanking from "../../images/mobileBank.png"


function MobileBanking() {

  const showModal = () => {
    document.getElementById("overlay").style.display = "block";
    document.getElementById("exampleModal").style.display = "block"
}


  return (
    <div className="text-black dark:text-white mobileBankingContainer">
        <div className="contentSection">
        <p className="uppercase" style={{color:"#757575",fontSize:"18px"}}>finedge mobile banking</p>
        <h1 className="mobileBankingTitle">Managing <br/> Mobile Banking</h1>
        <p>Combining outstanding functionalities with a well- designed user experience to promote financial well- being among customers</p>
    
    <div className="my-6">
        <button onClick={()=>showModal()}  className="scheduleButton flex my-5">Schedule Demo <BsArrowDown className="mt-1 ml-2"/></button>
    </div>

    </div>


    <div>
        <img src={mobileBanking} alt="mobileBanking"/>
    </div>
    
    </div>
  )
}

export default MobileBanking
import React from "react";
import "./BankingPackage.css";
import BankingFeature from "./BankingFeature";
import cardsBlack from "../../images/cardsBlack.svg"
import cardsGreen from "../../images/cardsGreen.svg"
import transferBlack from "../../images/transferBlack.svg"
import transferGreen from "../../images/transferGreen.svg"
import airtimeBillBlack from "../../images/airtimeBillsBlack.svg"
import airtimeBillGreen from "../../images/airtimeBillsGreen.svg"
import customerBlack from "../../images/customerBlack.svg"
import customerGreen from "../../images/customerGreen.svg"
import secureBlack from "../../images/secureBlack.svg"
import secureGreen from "../../images/secureGreen.svg"
import customerServiceBlack from "../../images/customerServiceBlack.svg"
import customerServiceGreen from "../../images/customerServiceGreen.svg"

function BankingPackage() {
  const [bankings] = React.useState([
    { title: "Account & transactions",icon:cardsBlack,iconBlack:cardsGreen},
    { title: "Fund transfer",icon:transferBlack,iconBlack:transferGreen},
    { title: "Airtime/data & bills",icon:airtimeBillBlack,iconBlack:airtimeBillGreen},
    { title: "Customer profile management",icon:customerBlack,iconBlack:customerGreen },
    { title: "Secure access",opacity:0.3,icon:secureBlack,iconBlack:secureGreen},
    { title: "Customer self services",opacity:0.3,icon:customerServiceBlack,iconBlack:customerServiceGreen },
  ]);

  return (
    <div className="bankingPackageContainer">
      <div>
        <p className="featureText">FEATURES</p>
        <h1 className="bankingPackageHeader text-black dark:text-white">
          Online Banking package
        </h1>
      </div>

      <div className="bankingGrid">
        {bankings.map((banking, i) => (
          <BankingFeature key={i}  banking={banking} />
        ))}
      </div>
    </div>
  );
}

export default BankingPackage;

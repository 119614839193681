import React from 'react'
import "./ProductCoverage.css"
import ProductGrid from './ProductGrid'

function ProductCoverage() {
    const [chosenTab,setChosenTab] = React.useState("consumer")
    const [featureData] = React.useState([
        {title:"Deposit",consumer:true, corporate:true,other:false, features:["Fixed rate deposits","Current and savings accounts","Term deposits","Top-up deposits & more..."]},
        {title:"Lending",consumer:true, corporate:true, other:false, features:["Personal Loans","Vehicle Loans","School Loans","Student Loans & more"]},
        {title:"Teller Operations",consumer:true, corporate:false, other:false, features:["Cash Deposits","Withdrawals & reconciliations","Cash vault management","Denomination management & more..."]},
        {title:"Servicing Operations",consumer:true, corporate:false, other:false, features:["Inventory management","Bulk/batch upload","Loan and deposit calculators","Fixed asset management"]},
        {title:"Placement & Other Operations",consumer:false, corporate:true, other:false, features:["Call placement","Fixed placement","Treasury bills","Commercial papers & more"]},
        {title:"Common Services",consumer:false, corporate:false, other:true, features:["Fixed rate Deposits","Current and savings account","Term Deposits","Top-up Deposits"]},
        {title:"Accounting", consumer:false, corporate:false, other:true,  features:["Term Loan","Restructuring","Loan servicing"]},
        {title:"Infrastructure Services", consumer:false, corporate:false, other:true,  features:["Call placement","Fixed placement","Treasury bills","Commercial paper & more"]},
    ])
    React.useEffect(()=>{
        document.getElementById("consumer").classList.add("tabButtonActive")
    },[])

    const makeActive = (e) =>{
        setChosenTab(e)
        document.getElementById("consumer").classList.remove("tabButtonActive")
        document.getElementById("corporate").classList.remove("tabButtonActive")
        document.getElementById("other").classList.remove("tabButtonActive")
        document.getElementById(e).classList.add("tabButtonActive")
        console.log(chosenTab)
        

    }



  return (
    <div id="learn-more" className="productContainer">
        <h1 className="text-black dark:text-white productCoverageTitle">Product Coverage</h1>
        <div className="text-black dark:text-white productCoverageDescription">Combining outstanding functionalities with a well- designed user
        experience to promote financial well- being among customers</div>


        <div className="my-9">
            <ul className="bg-gray-100 dark:bg-gray-600 text-black dark:text-white tabContainer flex">
                <li onClick={()=>makeActive("consumer")} id="consumer" className="tabButton">Consumer Banking</li>
                <li onClick={()=>makeActive("corporate")} id="corporate" className="tabButton">Corporate Banking</li>
                <li onClick={()=>makeActive("other")} id="other" className="tabButton">Others</li>
            </ul>
        </div>


        <div className="gridFeatureContainer">
            {
            featureData.map((feature,i)=>( (chosenTab==='consumer'?feature.consumer:chosenTab==='corporate'?feature.corporate:feature.other) && <ProductGrid  feature={feature} key={i}/>)
        )}
        </div>
        
    </div>
  )
}

export default ProductCoverage
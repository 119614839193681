import React from 'react'


function Banking(props) {
  return (
    <div style={{opacity:props.banking.opacity}} className="bankingContainer dark:text-white">
        <img  className="my-4" src={localStorage.getItem("color-theme") ==='light'?props.banking.icon:props.banking.iconBlack} alt="icon"/>
        <h1 className="text-xl bankingTitle">{props.banking.title}</h1>
    </div>
  )
}

export default Banking
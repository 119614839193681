import React from 'react'
import './Promises.css'
import Promise from './Promise'
import naira from "../../images/naira.svg"
import nairaBlack from "../../images/nairaBlack.svg"
import briefcase from "../../images/briefcase.svg"
import globeIcon from "../../images/globeIcon.svg"
import verified from "../../images/verified.svg"
import verifiedBlack from "../../images/verifiedBlack.svg"
import upload from "../../images/upload.svg"
import uploadBlack from "../../images/uploadBlack.svg"
import support from "../../images/support.svg"
import headphoneBlack from "../../images/headphoneBlack.svg"
import arrowDown from "../../images/arrowDown.svg"
import globeIconBlack from "../../images/globeBlack.svg"
import briefcaseBlack from "../../images/briefcaseBlack.svg"
import arrowWhite from "../../images/arrowWhites.svg"

function Promises() {
 const [promises] = React.useState([
    {icon:naira, title:"Reduced cost",iconBlack:nairaBlack},
    {icon:briefcase, title:"Efficient business experience",iconBlack:briefcaseBlack},
    {icon:globeIcon, title:"Services centralization",iconBlack:globeIconBlack},
    {icon:verified, title:"Seamless integration options",iconBlack:verifiedBlack},
    {icon:upload, title:"Increased extensibility & white labelling",iconBlack:uploadBlack},
    {icon:support, title:"Improved customer service experience",iconBlack:headphoneBlack},
 ])
  return (
    <div>
        <div className="my-4 promisesTab">
       <img src={localStorage.getItem("color-theme")==='light'?arrowDown:arrowWhite} className="arrowImage" alt="arrowDown"/>
        <h1 className="promisesHeader dark:text-white">Promises we make and keep  to all our <span className="valuedCustomers">valued customers</span></h1>
        </div>

 <div className="promiseGrid">
   {promises.map((promise,i)=>(
    <Promise key={i} promise= {promise} />
   ))}
   </div>
    
    </div>
  )
}

export default Promises
import React from "react";
import "./Hero.css";
import { FiArrowRight, FiArrowDown } from "react-icons/fi";


function Hero() {
  return (
    <div className="heroContainer">
      <h1 className="animate__animated animate__fadeInUp animate__faster text-black dark:text-white">
        Integrated <br /> banking & payment <br />
        automation solution
      </h1>
      <div className="animate__animated animate__fadeInUp text-black dark:text-white heroDescription">
        End to end automation of financial institution operations by exposing
        core services to end consumers through alternative channels
      </div>

      <div className="animate__animated animate__fadeInLeft animate__slow animate__delay-1s heroAction">
        <button className="learnMoreButton">
          <a href="#learn-more" className="flex justify-center">
            Learn More <FiArrowRight className="m-1 ml-5" />
          </a>
        </button>
        <button className="secondaryBtn text-black dark:text-white ml-6">
          <a href="#alternate-channels" className="flex justify-center">
            Alternative Channels <FiArrowDown className="m-1 ml-3" />
          </a>
        </button>
      </div>
    </div>
  );
}

export default Hero;

import React from 'react'
import "./alternateChannels.css"
import atm from "../../images/atm.png"
import pos from "../../images/pos.png"
import vr from "../../images/vr.png"
import posmachine from "../../images/posmachine.png"
import mobilephone from "../../images/mobilePhone.png"
import internet from "../../images/internet.png"

function AlternateChannels() {
  return (
    <div id="alternate-channels" className="alternateChannels">
      <div className="alternateGrid">
      <img src={posmachine} alt="posmachine" />
      <img src={vr} alt="atm"/>
      <img src={mobilephone} alt="pos" />
      <img src={atm} alt="pos" />
      <img src={pos} alt="phone" />
      <img src={internet} alt="vr" />
    </div>

    </div>
  
  )
}

export default AlternateChannels
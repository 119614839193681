import React from 'react'

function Promise(props) {
  return (
    <div className="promiseCard  dark:text-gray-500">
        <img className="promiseImage" width={"22px"} src={localStorage.getItem("color-theme") ==='light'?props.promise.icon:props.promise.iconBlack} alt="icon"/>
        <h1 className="text-xl promiseTitle dark:text-white">{props.promise.title}</h1>
    </div>
  )
}

export default Promise
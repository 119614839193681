import React from 'react'
import "./Finedge.css"
import dot from '../../images/dot.svg'
import bankIcon from "../../images/bankIcon.svg"


function Finedge() {
  return (
    <div id="core-banking" className="finedgeCore relative text-black dark:text-white">
        <h1 className="coreBanking">FinEdge <br/>Core Banking</h1>
        <p className="finedgeParagraph">FinEdge core banking is an advanced cloud-native internet banking and mobile banking solution that helps banks onboard, service and engage more efficiently. The core banking suite line consists:</p>
        <div className="finedgeGrid">
            <div className="flex">
            <img src={dot} alt=""/><span className="m-2 ">Deposits</span>
            </div>

            <div  className="flex">
            <img src={dot} alt=""/><span className="m-2">Lending</span>
            </div>
            
            
            <div className="flex">
            <img src={dot} alt=""/><span className="m-2">Placements</span>
            </div>

            <div className="flex">
            <img src={dot} alt=""/><span className="m-2">Banking Operations</span>
            </div>

        </div>

        <div className="bankIcon absolute top-0 right-0">
            <img src={bankIcon} alt="bankIcon" />
        </div>
        
        </div>
  )
}

export default Finedge